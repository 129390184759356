export const strict = false

const getDefaultState = () => {
  return {
    // colors
    bgColor: undefined,
    variantColor: undefined,

    // settings
    settings: {},

    // user
    user: {},

    // Album 1
    album1Info: {},
    album1Photos: [],
    album1PhotosIsLastPage: false,
    album1PhotosPage: 1,

    // Album 2
    album2Info: {},
    album2Photos: [],
    album2PhotosIsLastPage: false,
    album2PhotosPage: 1,

    // Album 3
    album3Info: {},
    album3Photos: [],
    album3PhotosIsLastPage: false,
    album3PhotosPage: 1,

    // Photo info dialog
    photoInfoDialogVisible: false,
    photoInfo: {}
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_PORTFOLIO: (state, payload) => {
    state.settings = payload
  },

  SET_USER: (state, payload) => {
    state.user = payload
  },

  SET_ALBUM: (state, payload) => {
    if (!state[`album${payload.payload.id}Info`].id) {
      state[`album${payload.payload.id}Info`] = payload.response.info
    }
    state[`album${payload.payload.id}Photos`] = [
      ...state[`album${payload.payload.id}Photos`],
      ...payload.response.result,
    ]
    state[`album${payload.payload.id}IsLastPage`] = payload.response.isLastPage
    state[`album${payload.payload.id}Page`] = payload.payload.page
  },

  SET_BG_COLOR: (state, payload) => {
    state.bgColor = payload
  },

  SET_VARIANT_COLOR: (state, payload) => {
    state.variantColor = payload
  },

  SET_PHOTO_INFO_DIALOG: (state, payload) => {
    state.photoInfoDialogVisible = payload.visible
    state.photoInfo = payload.photo || {}
  },

  RESET_STORE: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async getPortfolio({ commit }, portfolioUrl) {
    try {
      const response = await this.$axios.$get(`/portfolio/${portfolioUrl}`)
      commit('SET_PORTFOLIO', response)
    } catch (error) {
      throw error.response
    }
  },

  async getUser({ commit }, id) {
    try {
      const response = await this.$axios.$get(`/users/${id}`)
      commit('SET_USER', response)
    } catch (error) {
      throw error.response
    }
  },

  async getAlbum({ commit }, payload) {
    const size = 30
    const page = payload.page
    const query = `?page=${page}&size=${size}`
    try {
      const response = await this.$axios.$get(
        `/albums/${payload.albumId}${query}`
      )
      const data = {
        payload,
        response,
      }
      commit('SET_ALBUM', data)
    } catch (error) {
      throw error.response
    }
  },

  resetStore({ commit }) {
    commit('RESET_STORE')
  },
}
