/**
 * @param {*} _url String: original image URL
 * @param {*} _type Enum[String]: 'thumb' | 'full'
 * @param {*} _watermark Boolean: if true, 100ASA overlay will be added
 * @returns Generated Image URL for ImageKit
 *
 * Possible transformations:
 * Thumbnails: n-thumb, n-thumb_d
 * Full: n-full_s, n-full_m, n-full_l, n-full_s_d, n-full_m_d, n-full_l_d, n-full_s_wm, n-full_m_wm, n-full_l_wm, n-full_s_d_wm, n-full_m_d_wm, n-full_l_d_wm
 *
 * S, M, L = Small (H 720), Medium (H 1440), Large (H 2160)
 * D = DPR
 * WM = WaterMark
 */
export default (_url, _type, _watermark, _isLocalStore, fast) => {
  if (!_url) {
    return
  }
  if (_isLocalStore) {
    return _url
  }
  const isThumb = _type && _type === 'thumb'
  const transformationVersion = isThumb ? '_v4' : '_v3'
  const oldTransformation = isThumb ? '/tr:n-thumbnail' : '/tr:n-full'
  const oldWm = '_wm'
  const removeOldTransformation = _url.replace(oldTransformation, '')
  const removeOldWm = removeOldTransformation.replace(oldWm, '')
  const finalUrl = removeOldWm
  const dpr = window.devicePixelRatio > 1
  const res =
    window.innerHeight <= 2160 ? (window.innerHeight <= 720 ? 's' : 'm') : 'l'
  const rest = finalUrl.substring(0, finalUrl.lastIndexOf('/') + 1)
  const last = finalUrl.substring(
    finalUrl.lastIndexOf('/') + 1,
    finalUrl.length
  )
  let transformation
  if (fast) {
    transformation = `tr:n-fast${dpr ? '_d' : ''}${transformationVersion}`
  } else {
    transformation = `tr:n-${isThumb ? 'thumb' : 'full'}${
      isThumb ? '' : `_${res}`
    }${dpr ? '_d' : ''}${
      _watermark || isThumb ? '' : '_wm'
    }${transformationVersion}`
  }
  const url = `${rest}${transformation}/${last}`
  return url
}
