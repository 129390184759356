import { hasSufficientPerformance, hasSufficientHardwareSetup, hasSufficientDownloadPerformance, setup } from 'nuxt-speedkit/utils/performance';
import { isSupportedBrowser } from 'nuxt-speedkit/utils/browser';

let init = false

async function initApp() {
  if (init) {
    return;
  }
  init = true;

  await new Promise(resolve => waitForIdle(resolve));
  return import('../client');
};

function waitForIdle (cb) {
  if ('requestIdleCallback' in global) {
    global.requestIdleCallback((deadline) => {
      const time = deadline.timeRemaining();
      if (time > 10 || deadline.didTimeout) {
        cb();
      } else {
        waitForIdle(cb);
      }
    }, { timeout: 2000 });
  } else {
    cb();
  }
};

function initFont() {
  global.document.querySelectorAll('[data-font]').forEach((node) => {
    node.classList.add('font-active');
  })
}

function observeSpeedkitButton (button, callback) {
  if (button) {
    button.addEventListener('click', callback, { capture: true, once: true, passive: true })
  }
}

function updateInfoLayer(item) {
  item.style.display = 'block'
  global.document.querySelector('#nuxt-speedkit-speedkit-layer').className += ' nuxt-speedkit-speedkit-layer-visible';
}

function setupSpeedkitLayer(callback, supportedBrowser) {
  if(!supportedBrowser) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit-button-unsupported-browser'));
  }
  if(!hasSufficientHardwareSetup()) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit-button-outdated-device'));
  }
  if(!hasSufficientDownloadPerformance()) {
    updateInfoLayer(global.document.getElementById('nuxt-speedkit-button-slow-connection'));
  }
  observeSpeedkitButton(global.document.getElementById('nuxt-speedkit-button-init-app'), callback);
}

const supportedBrowser = isSupportedBrowser({regex: new RegExp("((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(12[_.]2|12[_.]([3-9]|\u005Cd{2,})|12[_.]5|12[_.]([6-9]|\u005Cd{2,})|(1[3-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|14[_.]0|14[_.]([1-9]|\u005Cd{2,})|14[_.]4|14[_.]([5-9]|\u005Cd{2,})|14[_.]8|14[_.](9|\u005Cd{2,})|(1[5-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|15[_.]0|15[_.]([1-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+)(?:[_.]\u005Cd+)?)|(Opera Mini(?:\u005C\u002Fatt)?\u005C\u002F?(\u005Cd+)?(?:\u005C.\u005Cd+)?(?:\u005C.\u005Cd+)?)|(Opera\u005C\u002F.+Opera Mobi.+Version\u005C\u002F(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Opera\u005C\u002F(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+.+Opera Mobi)|(Opera Mobi.+Opera(?:\u005C\u002F|\u005Cs+)(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|((?:Chrome).*OPR\u005C\u002F(82|(8[3-9]|9\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(SamsungBrowser\u005C\u002F(15|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Edge\u005C\u002F(96|(9[7-9]|\u005Cd{3,}))(?:\u005C.\u005Cd+)?)|((Chromium|Chrome)\u005C\u002F(96|(9[7-9]|\u005Cd{3,}))\u005C.\u005Cd+(?:\u005C.\u005Cd+)?)|(Version\u005C\u002F(14\u005C.1|14\u005C.([2-9]|\u005Cd{2,})|(1[5-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+|15\u005C.2|15\u005C.([3-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)(?:\u005C.\u005Cd+)? Safari\u005C\u002F)|(Trident\u005C\u002F7\u005C.0)|(Firefox\u005C\u002F(78|(79|[8-9]\u005Cd|\u005Cd{3,})|91|(9[2-9]|\u005Cd{3,})|95|(9[6-9]|\u005Cd{3,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(Firefox\u005C\u002F(78|(79|[8-9]\u005Cd|\u005Cd{3,})|91|(9[2-9]|\u005Cd{3,})|95|(9[6-9]|\u005Cd{3,}))\u005C.\u005Cd+(pre|[ab]\u005Cd+[a-z]*)?)|(([MS]?IE) (11|(1[2-9]|[2-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)", "")});

if (!document.getElementById('nuxt-speedkit-speedkit-layer')) {
  initApp();
} else {
  setup({"device":{"hardwareConcurrency":{"min":2,"max":48},"deviceMemory":{"min":2}},"timing":{"fcp":800,"dcl":1200}});

  setupSpeedkitLayer(initApp, supportedBrowser)

  if(('__NUXT_SPEEDKIT_AUTO_INIT__' in global && global.__NUXT_SPEEDKIT_AUTO_INIT__) || ((true && hasSufficientPerformance()) && supportedBrowser)) {
    initApp();
  }

  observeSpeedkitButton(global.document.getElementById('nuxt-speedkit-button-init-font'), initFont);

  if('__NUXT_SPEEDKIT_FONT_INIT__' in global && global.__NUXT_SPEEDKIT_FONT_INIT__) {
    initFont()
  }
}
