export const CommonFooter = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const PortfolioLoadingTemplate = () => import('../../components/portfolio/LoadingTemplate.vue' /* webpackChunkName: "components/portfolio-loading-template" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTemplateA = () => import('../../components/portfolio/TemplateA.vue' /* webpackChunkName: "components/portfolio-template-a" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTemplateAAbout = () => import('../../components/portfolio/TemplateA/About.vue' /* webpackChunkName: "components/portfolio-template-a-about" */).then(c => wrapFunctional(c.default || c))
export const PortfolioTemplateAAlbums = () => import('../../components/portfolio/TemplateA/Albums.vue' /* webpackChunkName: "components/portfolio-template-a-albums" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsAlbumDialog = () => import('../../components/portfolio/albums/AlbumDialog.vue' /* webpackChunkName: "components/portfolio-albums-album-dialog" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsJustified = () => import('../../components/portfolio/albums/Justified.vue' /* webpackChunkName: "components/portfolio-albums-justified" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsMultipleAlbums = () => import('../../components/portfolio/albums/MultipleAlbums.vue' /* webpackChunkName: "components/portfolio-albums-multiple-albums" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsPhotoInfoDialog = () => import('../../components/portfolio/albums/PhotoInfoDialog.vue' /* webpackChunkName: "components/portfolio-albums-photo-info-dialog" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsSingleAlbum = () => import('../../components/portfolio/albums/SingleAlbum.vue' /* webpackChunkName: "components/portfolio-albums-single-album" */).then(c => wrapFunctional(c.default || c))
export const PortfolioAlbumsSwiper = () => import('../../components/portfolio/albums/Swiper.vue' /* webpackChunkName: "components/portfolio-albums-swiper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
