import types from '../utils/types'
import getImageUrl from '@/helpers/transformer'

const tinycolor = require("tinycolor2")

export default function (ctx, inject) {
  const asa = {}

  asa.getFriendlyPhotoStatusName = status => {
    switch (status) {
      case types.photo.status.uploaded:
        return 'uploaded'
      case types.photo.status.inspiration:
        return 'inspiration'
      case types.photo.status.elite:
        return 'elite'
      case types.photo.status.prime:
        return 'prime'
      case types.photo.status.upcoming:
        return 'upcoming'
      case types.photo.status.opening:
        return 'opening'
      case types.photo.status.album:
        return 'in album'
      case types.photo.status.inCuration:
        return 'in curation'
      default:
        return 'unknown'
    }
  }

  asa.getImageUrl = (photo, type, fast = false) => {
    if (photo && (photo.url || photo.thumbnailUrl)) {
      const photoPath = type === 'full' ? photo.url : photo.thumbnailUrl
      const isLocalStore = !photoPath.includes('storage.100asa.com')
      return getImageUrl(
        photoPath,
        type,
        photo.personalWatermark || false,
        isLocalStore,
        fast
      )
    }
  }

  asa.initTemplateColor = (data) => {
    // Dark template
    if (data.colorSchema === 'dark') {
      ctx.store.commit('portfolio/SET_VARIANT_COLOR', '#212121')
    }

    // Light template
    if (data.colorSchema === 'light') {
      ctx.$vuetify.theme.light = true
      ctx.$vuetify.theme.dark = false
      const bgColor = '#fafafa'
      const variantColor = tinycolor(bgColor).darken(5).toString()
      ctx.store.commit('portfolio/SET_BG_COLOR', bgColor)
      ctx.store.commit('portfolio/SET_VARIANT_COLOR', variantColor)
    }

    // Navi template
    if (data.colorSchema === 'navi') {
      const bgColor = '#263238'
      const variantColor = '#37474F'
      ctx.store.commit('portfolio/SET_BG_COLOR', bgColor)
      ctx.store.commit('portfolio/SET_VARIANT_COLOR', variantColor)
    }

    // Custom template
    if (data.colorSchema === 'custom') {
      if (data.textColor === 'light') {
        ctx.$vuetify.theme.light = true
        ctx.$vuetify.theme.dark = false
      }
      const bgColor = data.primaryColor
      const variantColor = data.textColor === 'light' ? tinycolor(bgColor).darken(5).toString() : tinycolor(bgColor).lighten(5).toString()
      ctx.store.commit('portfolio/SET_BG_COLOR', bgColor)
      ctx.store.commit('portfolio/SET_VARIANT_COLOR', variantColor)
    }
  }

  asa.isBackgroundDark = (data) => {
    return tinycolor(data).isDark()
  }

  inject('asa', asa)
}
