import https from 'https'

export default function ({ $axios, app, redirect }) {
  const agent = new https.Agent({
    rejectUnauthorized: false,
  })

  // Axios default options
  $axios.defaults.baseURL = process.env.apiBaseUrl
  $axios.setHeader('Content-Type', 'application/json')
  $axios.setHeader('Access-Control-Allow-Origin', '*')

  $axios.onRequest((config) => {
    config.httpsAgent = agent
  })
}
