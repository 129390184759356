export default {
  photo: {
    status: {
      uploaded: 'UPLOADED',
      inCuration: 'IN_CURATION',
      inspiration: 'INSPIRATION',
      elite: 'ELITE',
      prime: 'PRIME',
      upcoming: 'UPCOMING',
      opening: 'OPENING',
      album: 'ALBUM'
    }
  }
}
